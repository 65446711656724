import React from 'react';
import './Key.css';

class Key extends React.Component {
  checkPressed(pressed) {
    if (pressed === true) {
      return (' key-pressed ')
    }

    return '';
  }

  render() {
    return (
      <div >
        <button type='button' className={
          'key ' + this.props.className
          + this.checkPressed(this.props.pressed)
        } >
          {
            this.props.shift ? <div> {this.props.shift} </div> : null
          }
          <div>
            {
              this.props.sticky ? <div className='key-add-sticky'></div> : null
            }
            {this.props.char}
            {this.props.guide ? <div className='key-guide'></div> : null}
          </div>
        </button>
      </div>
    );
  }
}

export default Key;