import './Header.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faKeyboard, faVolumeUp, faFeather, faUser, faBars, faUserCircle, faSignOutAlt
} from '@fortawesome/free-solid-svg-icons';

import {
  UncontrolledTooltip, UncontrolledDropdown,
  DropdownToggle, DropdownMenu, DropdownItem,
  Navbar, Nav, NavbarBrand, NavItem
} from 'reactstrap';
import API from './../../common/api';
import { sessionService } from 'redux-react-session';

const Header = (props) => {

  const [selectLessonDisplay, setSelectLessonDisplay] = useState(true);
  const [keyboardDisplay, setKeyboardDisplay] = useState(true);
  const [volumeOnOff, setVolumeOnOff] = useState(true);

  const setUserLoggedinStatus = (event) => {
    if (props.session.authenticated === false) {
      // do the default action - toggle the icon
    } else {
      event.preventDefault();
    }
  }

  /**
   * Handle logout by clearing and destroying session related data
   * @param {*} event 
   */
  const handleLogout = (event) => {
    event.preventDefault();

    API.HandleLogout((error, response) => {
      sessionService.deleteSession()
        .then(() => {
          sessionService.deleteUser();
          window.localStorage.clear();
          window.sessionStorage.clear();
        })
        .then(() => {
          window.location.href = '/login';
        })
        .catch(() => {
          console.error('SessionService failed deleting session');
        });
    });
  }

  const SignIn = (props) => {
    return (
      !props.loginStatus ?
        <>
          <UncontrolledDropdown navbar='true'>
            <Link id='idSignIn' to='/login' onClick={props.onClick}>
              <FontAwesomeIcon
                className={props.loginStatus ? 'icon-enabled' : 'icon-disabled'}
                icon={faUser}
              />
            </Link>
            <UncontrolledTooltip target='idSignIn'>
              Sign in or Sign Up to save progress
            </UncontrolledTooltip>
          </UncontrolledDropdown>
        </>
        : <>
          <UncontrolledDropdown nav className='profile'>
            <DropdownToggle nav caret>
              <FontAwesomeIcon
                className={props.loginStatus ? 'icon-enabled' : 'icon-disabled'}
                icon={faUser}
              />
            </DropdownToggle>
            <DropdownMenu right>
              <Link to='/profile'>
                <DropdownItem>
                  <FontAwesomeIcon icon={faUserCircle} /> &nbsp;Profile
                </DropdownItem>
              </Link>
              <DropdownItem onClick={handleLogout}>
                <FontAwesomeIcon icon={faSignOutAlt} />&nbsp;Logout
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
    );
  }

  return (
    <header className='header'>
      <Navbar expand='md justify-content-between'>
        <NavbarBrand className='logo' href='https://www.typingfly.com'>
          Typing<FontAwesomeIcon className='feather' icon={faFeather} />Fly
        </NavbarBrand>
        <Nav justified>
          <NavItem className='nav-item1'>
            <Link id='idLessons' to='/lessons' onClick={() => { setSelectLessonDisplay(!selectLessonDisplay) }}>
              <FontAwesomeIcon
                className={selectLessonDisplay ? 'icon-enabled' : 'icon-disabled'}
                icon={faBars}
              />
            </Link>
            <UncontrolledTooltip target='idLessons'>
              Select Keyboard practice lesson
              </UncontrolledTooltip>
          </NavItem>

          <NavItem className='nav-item1'>
            <Link id='idToggleKeyboard' to='/writer' onClick={() => { setKeyboardDisplay(!keyboardDisplay) }}>
              <FontAwesomeIcon
                className={keyboardDisplay ? 'icon-enabled' : 'icon-disabled'}
                icon={faKeyboard}
              />
            </Link>
            <UncontrolledTooltip target='idToggleKeyboard'>
              Toggle Onscreen Keyboard display
              </UncontrolledTooltip>
          </NavItem>

          <NavItem className='nav-item1'>
            <Link id='idVolumeOnOff' to='#home' onClick={() => { setVolumeOnOff(!volumeOnOff) }}>
              <FontAwesomeIcon
                className={volumeOnOff ? 'icon-enabled' : 'icon-disabled'}
                icon={faVolumeUp}
              />
            </Link>
            <UncontrolledTooltip target='idVolumeOnOff'>
              Toggle Volume On - Off
             </UncontrolledTooltip>
          </NavItem>

          <div className='nav-item1'>
            <SignIn loginStatus={props.session.authenticated} onClick={setUserLoggedinStatus} />
          </div>

        </Nav>
      </Navbar>
    </header >
  )
};

const mapStateToProps = ({ session }) => {
  return ({
    session: session
  });
}

export default connect(mapStateToProps)(Header);