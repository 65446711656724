import React, { useState } from 'react';
import './License.css';
import {
  Container, Row, Col, Collapse, Button,
  Card, CardBody, CardHeader, CardFooter, CardDeck
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const licenses = require('./licenses.json');
const LicenseList = (props) => {
  const list = licenses.list;
  const [itemSelected, SetItemSelected] = useState(-1);

  const handleClick = (event) => {
    if (itemSelected !== event.currentTarget.dataset.index) {
      SetItemSelected(event.currentTarget.dataset.index);
    } else {
      SetItemSelected(-1);
    }
  }

  const isOpen = (index) => {
    return (itemSelected == index)
  }

  return (
    <div>
      <p className='notice'>
        {licenses.notice} <br /><br />
        {licenses.notice2}
      </p>
      {
        licenses.list.map((item, index) =>
          <div key={index.toString()}>
            <Card>
              <CardHeader className={isOpen(index) ? 'card-active' : ''}>
                <Row onClick={handleClick} data-index={index}>
                  <Col md={4}>
                    {
                      item.title
                    }
                  </Col>
                  <Col md={7}>
                    {
                      item.license_type
                    }
                  </Col>
                  <Col className='text-right' md={1}>
                    <FontAwesomeIcon className={isOpen(index) ? '' : 'site-primary-color'} icon={isOpen(index) ? faChevronUp : faChevronDown} />
                  </Col>
                </Row>
              </CardHeader>
              <Collapse isOpen={isOpen(index)}>
                <CardBody>
                  <p>
                    <a href={item.home_link} target='_blank' rel='noreferrer noopener'>{item.home_link}</a>
                  </p>
                  <p>
                    <a href={item.license_link} target='_blank' rel='noreferrer noopener'>{item.license_link}</a>
                  </p>
                  <p>
                    {item.text}
                  </p>
                </CardBody>
              </Collapse>
            </Card>
          </div>
        )
      }
    </div>
  );
}

const License = () => {
  return (
    <div className='container-desktop license-container'>
      <Row>
        <Col md={12}>
          <div className='list-container'>
            <LicenseList />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default License;