import React from 'react';
import {
  Container, Row, Col,
  FormGroup, Button, Label, FormText
} from 'reactstrap';
import * as Yup from 'yup';
import {
  Formik, Form as FormikForm, ErrorMessage, Field as FormikField
} from 'formik';
import './UserProfile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlock, faUnlockAlt, faLock } from '@fortawesome/free-solid-svg-icons';

/*
  props = {
    onSubmit
  }
*/

const ChangePasswordForm = (props) => {
  const initialFormValues = {
    currentSecretkey7: '',
    secretkey7: '',
    confirmSecretkey7: ''
  };

  const formValidationSchema = Yup.object().shape({
    currentSecretkey7: Yup.string()
      .required('Password is required')
      .min(8, 'Minimum 8 chars required')
      .max(36, 'Maximum of 36 characters'),
    secretkey7: Yup.string()
      .required('Password is required')
      .min(8, 'Password length must be between 8 - 36 characters')
      .max(36, 'Password length must be between 8 - 36 characters')
      .matches(/^.*(?=.{8,})((?=.*[!@#$%^&*()]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
        , 'Strong password requires a capital letter[A-Z], 1 small letter[a-z], 1 number[0-9] and 1 of the allowed special characters !@#$%^&*()'),
    confirmSecretkey7: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('secretkey7')], 'Passwords must match')
  });

  const form = ({ errors, status, touched }) => {
    return (
      <FormikForm className='form'>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <FontAwesomeIcon className='icon' icon={faUnlock} /> &nbsp;<Label for='currentSecretkey7'>Current Password</Label>
              <FormikField type='password'
                name='currentSecretkey7'
                id='currentSecretkey7'
                className={'form-control pwd' + (errors.currentSecretkey7 && touched.currentSecretkey7 ? ' is-invalid ' : '')}
                placeholder='********' />
              <ErrorMessage name='currentSecretkey7' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <FontAwesomeIcon className='icon' icon={faUnlockAlt} /> &nbsp;<Label for='secretkey7'>New Password</Label>
              <FormikField type='password'
                name='secretkey7'
                id='secretkey7'
                className={'form-control pwd' + (errors.secretkey7 && touched.secretkey7 ? ' is-invalid ' : '')}
                placeholder='********' />
              <ErrorMessage name='secretkey7' component='div' className='invalid-feedback' />
              <FormText color='muted'>Password length must be between 8 - 36 characters with a capital letter, 1 small letter, 1 number and 1 of the allowed special characters !@#$%^&*()</FormText>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12} >
            <FormGroup>
              <FontAwesomeIcon className='icon' icon={faLock} /> &nbsp;<Label for='confirmSecretkey7'>Confirm Password</Label>
              <FormikField type='password'
                name='confirmSecretkey7'
                id='confirmSecretkey7'
                className={'form-control pwd' + (errors.confirmSecretkey7 && touched.confirmSecretkey7 ? ' is-invalid ' : '')}
                placeholder='' />
              <ErrorMessage name='confirmSecretkey7' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Button type='submit' color='primary'>Save</Button>
        </FormGroup>
      </FormikForm>
    );
  }

  return (
    <Container className='user-profile-form'>
      <Formik
        initialValues={initialFormValues}
        validationSchema={formValidationSchema}
        onSubmit={props.onSubmit} >
        {form}
      </Formik>
    </Container >
  );
}

export default ChangePasswordForm;
