import React from 'react';
import './Feedback.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentDots
} from '@fortawesome/free-solid-svg-icons';
import { isNullOrUndefined } from 'util';
import FeedbackRating from './FeedbackRating';

const Feedback = (props) => {

  /**
   * Toggle feedback icon visibility. Close after 10 secs
   * @param {*} target 
   */
  const showFeedback = (target) => {
    const ele = document.getElementById(target);
    if (!isNullOrUndefined(ele)) {
      if (ele.style.display === 'none') {
        ele.style.display = 'block';
        setTimeout(() => { hideFeedback(target); }, 10000);
      } else {
        ele.style.display = 'none';
      }
    }
  }

  /**
   * Hide the feedback icon
   * @param {*} target 
   */
  const hideFeedback = (target) => {
    const ele = document.getElementById(target);
    if (!isNullOrUndefined(ele)) {
      if (ele.style.display !== 'none') {
        ele.style.display = 'none';
      }
    }
  }

  return (
    <div>
      <FeedbackRating id='feedbackRating' vertical={props.vertical} hideCallback={hideFeedback} supportDialog={true} />
      <FontAwesomeIcon id='comment' className='feedback comment circle' icon={faCommentDots}
        onMouseEnter={(event) => {
          event.preventDefault();
          showFeedback('feedbackRating');
        }}
      />
    </div>
  );
}

export default Feedback;