import './FeedbackForm.css'
import React from 'react';
import {
  Container, Row, Col,
  FormGroup, Button, Label, FormText, Input
} from 'reactstrap';
import * as Yup from 'yup';
import {
  Formik, Form as FormikForm, ErrorMessage, Field as FormikField
} from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGrinStars, faGrin, faFrown, faMeh, faSmile
} from '@fortawesome/free-solid-svg-icons';
import { isNullOrUndefined } from 'util';

const FeedbackForm = (props) => {
  const initialFormValues = {
    rating: (isNullOrUndefined(props.rating) ? 0 : props.rating),
    message: ''
  };

  const formValidationSchema = Yup.object().shape({
    message: Yup.string()
      .min(10, 'Minimum a sentence with atleast 10 characters required')
      .max(500, 'Maximum allowed is 500 characters')
      .required('Please describe your experience')
  });

  const form = ({ values, errors, status, touched }) => {
    return (
      <FormikForm>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <FormikField as='textarea'
                name='message'
                rows='7'
                className={'form-control' + (errors.message && touched.message ? ' is-invalid ' : '')}
                placeholder='Please describe your experience and how we can make it better.' />

              <FormText color='muted'>Atleast 10 characters to the maximum of 500 characters</FormText>
              <ErrorMessage name='message' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={12}>
            <FormGroup check>
              <Label for='rating'>Overall, how was your experience ?</Label>
              <div className='feed-rating-checkbox'>
                <Label>
                  <Input type='radio' name='rating' defaultChecked={values.rating === 5} />
                  <FontAwesomeIcon className='awesome' icon={faGrinStars} />
                </Label>
                <Label>
                  <Input type='radio' name='rating' defaultChecked={values.rating === 4} />
                  <FontAwesomeIcon className='good' icon={faGrin} />
                </Label>
                <Label>
                  <Input type='radio' name='rating' defaultChecked={values.rating === 3} />
                  <FontAwesomeIcon className='ok' icon={faSmile} />
                </Label>
                <Label>
                  <Input type='radio' name='rating' defaultChecked={values.rating === 2} />
                  <FontAwesomeIcon className='improve' icon={faMeh} />
                </Label>
                <Label>
                  <Input type='radio' name='rating' defaultChecked={values.rating === 1} />
                  <FontAwesomeIcon className='attention' icon={faFrown} />
                </Label>
              </div>
              <FormText color='muted'>
                Experience ranging from awesome <FontAwesomeIcon className='awesome' icon={faGrinStars} />
                      (5 stars) to not satisfied <FontAwesomeIcon className='attention' icon={faFrown} /> (1 star)
                    <ErrorMessage name='rating' component='div' className='invalid-feedback' />
              </FormText>
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={12}>
            <FormGroup>
              <Button type='submit' color='primary'>Submit</Button>
            </FormGroup>
          </Col>
        </Row>
      </FormikForm>
    );
  }

  return (
    <Container className='feedback-form'>
      <Formik
        initialValues={initialFormValues}
        validationSchema={formValidationSchema}
        onSubmit={props.onSubmit} >
        {form}
      </Formik>
    </Container >
  );
}

export default FeedbackForm;