import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFeather, faFileContract, faBalanceScale, faUserSecret, faInfoCircle, faAt
} from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

class Footer extends React.Component {
  render() {
    return (
      <footer className='footer'>
        <Nav className='nav-content'>
          <Nav vertical className='nav-vertical'>
            <NavItem className='footer-vertical-item'>
              &copy; 2020 Typing<FontAwesomeIcon icon={faFeather} />Fly
            </NavItem>
          </Nav>

          <Nav vertical className='nav-vertical'>
            <NavItem className='footer-vertical-item'>
              <Link className='link' to='/about'>
                <FontAwesomeIcon icon={faInfoCircle} /> About Us
                </Link>
            </NavItem>
            <NavItem className='footer-vertical-item'>
              <Link className='link' to='/contact'>
                <FontAwesomeIcon icon={faAt} /> Contact
                </Link>
            </NavItem>
          </Nav>

          <Nav vertical className='nav-vertical'>
            <NavItem className='footer-vertical-item'>
              Follow us
            </NavItem>
            <NavItem className='footer-vertical-item'>
              <a className='link' href='https://www.linkedin.com/showcase/typingfly' target='_blank' rel='external noopener noreferrer nofollow'>
                <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
              </a>
            </NavItem>
          </Nav>

          <NavItem className='footer-item'>
            <Nav vertical className='nav-vertical'>
              <NavItem className='footer-vertical-item'>
                <a className='link' href='/policy' target='_blank' rel='external noopener noreferrer nofollow'>
                  <FontAwesomeIcon icon={faUserSecret} /> Privacy Policy
                </a>
              </NavItem>
              <NavItem className='footer-vertical-item'>
                <a className='link' href='/terms' target='_blank' rel='external noopener noreferrer nofollow'>
                  <FontAwesomeIcon icon={faBalanceScale} /> Terms of Use
                </a>
              </NavItem>
            </Nav>
          </NavItem>

          <NavItem className='footer-item'>
            <Nav vertical className='nav-vertical'>
              <NavItem className='footer-vertical-item'>
                <a className='link' href='https://www.typingfly.com/dmca' target='_blank' rel='external noopener noreferrer nofollow'>
                  <FontAwesomeIcon icon={faBalanceScale} /> DMCA Notice
                </a>
              </NavItem>
              <NavItem className='footer-vertical-item'>
                <Link className='link' to='/licenses'>
                  <FontAwesomeIcon icon={faFileContract} /> Open source licenses
                </Link>
              </NavItem>
            </Nav>
          </NavItem>
        </Nav>
      </footer >
    );
  }
}

export default Footer;