import React from 'react';
import './TypeWriter.css';
import Keyboard from './../Keyboard/Keyboard';
import DisplayPad from '../DisplayPad/DisplayPad';
import typeWriterKeyTap from './../../resources/sounds/typewriter-key-tap1.mp3';
import typeWriterKeyTapSpace from './../../resources/sounds/typewriter-key-space1.mp3';
import { isUndefined } from 'util';
import { connect } from 'react-redux';
import { activeLesson, nextLesson } from './../actions/actionLesson';
import { isModalDialogOpen } from './../../common';

class TypeWriter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      keyPressed: null,
      showOnscreenKeyboard: true,
      volumeLevel: 100,
      volumeOnOff: true
    };

    this.audio = new AudioContext();
  }

  componentDidMount = () => {
    document.getElementById('idToggleKeyboard').addEventListener('click', this.handleToggleKeyboard, false);
    //document.getElementById('idThemePalette').addEventListener('click', this.handleThemePalette, false);
    document.getElementById('idVolumeOnOff').addEventListener('click', this.handleVolumeOnOff, false);

    document.addEventListener('focus', this.handleFocusIn, false);
    document.addEventListener('blur', this.handleFocusOut, false);
  }

  componentWillUnmount = () => {
    document.getElementById('idToggleKeyboard').removeEventListener('click', this.handleToggleKeyboard, false);
    //document.getElementById('idThemePalette').removeEventListener('click', this.handleThemePalette, false);
    document.getElementById('idVolumeOnOff').removeEventListener('click', this.handleVolumeOnOff, false);

    document.removeEventListener('focus', this.handleFocusIn, false);
    document.removeEventListener('blur', this.handleFocusOut, false);
  }


  handleFocusIn = (event) => {
    console.log('Typeweriter - foucusin')
    if (!isModalDialogOpen()) {
      console.log('Typeweriter - foucusin')
    }
  }

  handleFocusOut = (event) => {
    console.log('Typeweriter - foucusout')
    if (isModalDialogOpen()) {
      console.log('Typeweriter - foucusout')
    }
  }

  handleKeyPressed = (event) => {
    this.setState((state, props) => ({
      keyPressed: event,
      lesson: state.lesson,
      showOnscreenKeyboard: state.showOnscreenKeyboard,
      volumeLevel: state.volumeLevel,
    }));
  }

  makeSound = (volume, frequency, duration) => {
    const oscillatorNode = this.audio.createOscillator();
    const gainNode = this.audio.createGain();

    oscillatorNode.connect(gainNode);
    gainNode.connect(this.audio.destination);

    oscillatorNode.frequency.value = frequency;
    oscillatorNode.type = 'triangle';

    gainNode.gain.value = volume * 0.01;

    oscillatorNode.start(this.audio.currentTime);
    oscillatorNode.stop(this.audio.currentTime + duration * 0.001);
  }

  playSound = (fileUrl) => {
    let request = new XMLHttpRequest();
    request.open('GET', fileUrl, true);
    request.responseType = 'arraybuffer';

    const audioSourceBuffer = this.audio.createBufferSource();
    request.onload = () => {
      this.audio.decodeAudioData(request.response, (audioBuffer) => {
        audioSourceBuffer.buffer = audioBuffer;
      });
    }

    request.send();

    audioSourceBuffer.connect(this.audio.destination);
    audioSourceBuffer.start(this.audio.currentTime);
  }

  makeKeyTapSound = (event) => {
    if (this.state.volumeOnOff) {
      if (!isUndefined(event) && event.code === 'Space') {
        this.playSound(typeWriterKeyTapSpace);
      } else {
        this.playSound(typeWriterKeyTap);
      }
    }
  }

  makeKeyTapFailureSound = () => {
    if (this.state.volumeOnOff) {
      this.makeSound(this.state.volumeLevel, 950, 100);
    }
  }

  handleToggleKeyboard = (event) => {
    event.preventDefault();

    this.setState((state, props) => ({
      keyPressed: state.keyPressed,
      lesson: state.lesson,
      showOnscreenKeyboard: !state.showOnscreenKeyboard,
      volumeLevel: state.volumeLevel,
    }));
  }

  handleVolumeOnOff = (event) => {
    event.preventDefault();

    this.setState((state, props) => ({
      volumeOnOff: !state.volumeOnOff,
    }));

    this.makeKeyTapSound();
  }

  handleThemePalette = (event) => {
    event.preventDefault();
  }

  handleLessonComplete = (lessonStatistics) => {
    console.log('Lesson Completed');
    this.props.nextLesson();
  }

  setNextLesson = (nextLesson) => {
    this.setState((state, props) => ({
      keyPressed: null,
      lesson: nextLesson,
      showOnscreenKeyboard: state.showOnscreenKeyboard,
      volumeLevel: state.volumeLevel,
    }));
  }

  render() {
    return (
      <div className='container writer'>
        <DisplayPad
          data={this.props.currentLesson}
          lessonComplete={this.handleLessonComplete}
          keypressSound={this.makeKeyTapSound}
          keypressFailureSound={this.makeKeyTapFailureSound}
        />
        {
          this.state.showOnscreenKeyboard ?
            <Keyboard id='idOnscreenKeyboard' onKeyPressed={this.handleKeyPressed} />
            : <></>
        }
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentLesson: state.reducerLesson,
    session: state.session
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLesson: () => dispatch(activeLesson()),
    nextLesson: () => dispatch(nextLesson())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TypeWriter);