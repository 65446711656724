import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { sessionService } from 'redux-react-session';
import { useHistory } from 'react-router-dom';
import { userStatus } from './../actions/actionStatus';
import API from './../../common/api';

const RefreshSession = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    API.verifyCurrentSession((error, response) => {
      if (error || response.status === 401) {
        return history.push('/login');
      }

      dispatch(userStatus(response.user));
      sessionService.saveUser(response.user);
      sessionService.saveSession(response.user);

      if (response.status === 200) {
        history.push('/');
      } else if (response.status === 201) {
        history.push('/profile');
      }
    });
  });

  return (
    <>
      {
        history.push('/')
      }
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userStatus: state.reducerUserStatus,
    session: state.session
  }
}

export default connect(mapStateToProps)(RefreshSession);