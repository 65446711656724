import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userStatus } from './../actions/actionStatus';
import classnames from 'classnames';
import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';

import { isNullOrUndefined } from 'util';
import './UserProfile.css';
import UserProfileData from '../../common/userprofiledata';
import { useAlert } from 'react-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserEdit, faUserShield } from '@fortawesome/free-solid-svg-icons';
import ChangePasswordForm from './ChangePasswordForm';
import UserProfileForm from './UserProfileForm';
import API from './../../common/api';

const UserProfile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();

  const [activeTab, setActiveTab] = useState(isNullOrUndefined(props.activeTab) ? 'password' : props.activeTab);
  const [userProfileData, setUserProfileData] = useState(new UserProfileData(props.userStatus.user));

  const setProfileData = (profileData) => {
    const data = new UserProfileData({ ...userProfileData.data, ...profileData });
    //set the state data
    setUserProfileData(data);
  }

  const handleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const updateUserData = () => {
    API.getUserData(props.session.user.id, (error, results) => {
      if (error) {
        console.log('Request failed')
      }

      if (results.status === 200) {
        setProfileData(results.user);
      } else {
        //session expired ?
        history.push('/login');
      }
    });
  }

  useEffect(() => {
    if (!isNullOrUndefined(props.session.user.id)) {
      updateUserData();
    } else {
      console.log('redirect to home ')
      history.push('/');
    }
  }, []);

  const handleUserProfileSubmit = (fields) => {
    console.log('fields');
    console.log(fields);

    if (userProfileData.getUserId() === '') {
      return;
    }

    console.log(props.userStatus);
    const bodyContent = {
      id: props.userStatus.user.id,
      authproviderid: props.userStatus.user.authproviderid,
      authprovider: props.userStatus.user.authprovider,
      email: fields.email,
      familyname: fields.lastname,
      givenname: fields.firstname,
      displayname: fields.name,
      dob: fields.dob !== '' ? fields.dob : null,
      sex: fields.sex,
      address: fields.address1,
      address2: fields.address2,
      country: fields.country,
      region: fields.region,
      city: fields.city,
      zip: fields.zip
    };


    console.log(bodyContent);

    const requestOptions = {
      method: 'PUT',
      crossDomain: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyContent)
    }
    console.log(requestOptions);
    //console.log(JSON.stringify(bodyContent));

    fetch('http://localhost:8080/api/users/v1/' + userProfileData.getUserId(), requestOptions)
      .then(async (response) => {
        const responseData = await response.json();
        console.log('Update Profile');
        console.log(responseData);

        if (response.status === 200) {
          alert.info('Personal information successfully changed');
        } else {
          alert.error('Saving personal information failed. Please try again!');
        }
      });
  }

  const handleLogout = () => {
    API.HandleLogout((error, responseData) => {

      console.log('Logout : ' + JSON.stringify(responseData));
      dispatch(userStatus({
        id: '',
        authprovider: '',
        authproviderid: ''
      }));
      window.location.href = '/login';
    });
  }

  const handleChangePassword = (fields) => {
    console.log('Change password')
    if (userProfileData.getUserId() === '') {
      return;
    }

    const bodyContent = {
      id: props.userStatus.user.id,
      authproviderid: props.userStatus.user.authproviderid,
      authprovider: props.userStatus.user.authprovider,
      authtoken: fields.secretkey7,
      authtoken1: fields.currrentSecretkey7
    };

    const requestOptions = {
      method: 'PUT',
      crossDomain: true,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyContent)
    }

    fetch('http://localhost:8080/api/users/v1/' + userProfileData.getUserId(), requestOptions)
      .then(async (response) => {
        const responseData = await response.json();
        console.log(responseData);

        if (response.status === 200) {
          alert.info('Password changed successfully. Please login with new password.');
          setTimeout(handleLogout, 1000);
        } else {
          alert.error('Password changed failed');
        }
      });
  }

  const ProfileTab = (props) => {
    return (
      <NavItem>
        <NavLink
          className={classnames({ active: props.active })}
          onClick={() => { props.onTabClick('profile') }}>
          <FontAwesomeIcon className='icon' icon={faUserEdit} /> &nbsp;Profile
          </NavLink>
      </NavItem>
    );
  }

  const ChangePasswordTab = (props) => {
    return (
      props.visible ?
        <NavItem>
          <NavLink
            className={classnames({ active: props.active })}
            onClick={() => { props.onTabClick('password') }}>
            <FontAwesomeIcon className='icon' icon={faUserShield} /> &nbsp;Change Password
          </NavLink>
        </NavItem>
        : <></>
    );
  }

  return (
    <div className='profile-container'>
      <Nav tabs>
        <ProfileTab
          visible={true}
          active={activeTab === 'profile'} onTabClick={handleTab} />
        <ChangePasswordTab
          visible={(props.userStatus.user.authprovider === 'local')}
          active={activeTab === 'password'} onTabClick={handleTab} />
      </Nav>

      <TabContent className='tab-content' activeTab={activeTab}>
        <TabPane className='tab-pane' tabId='profile'>
          <UserProfileForm
            profileData={userProfileData}
            onSubmit={handleUserProfileSubmit} />
        </TabPane>
        <TabPane className='tab-pane' tabId='password'>
          <ChangePasswordForm onSubmit={handleChangePassword} />
        </TabPane>
      </TabContent>
    </div >
  );
}

const mapStateToProps = (state) => {
  return {
    userStatus: state.reducerUserStatus,
    session: state.session
  }
}

export default connect(mapStateToProps)(UserProfile);