import './UserProfile.css';
import React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import {
  Container, Row, Col,
  FormGroup, Button, Label
} from 'reactstrap';
import * as Yup from 'yup';
import {
  Formik, Form as FormikForm, ErrorMessage, Field as FormikField
} from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserTag, faAt, faCalendarDay, faAddressCard, faGenderless
} from '@fortawesome/free-solid-svg-icons';

const UserProfileForm = (props) => {

  const initialFormValues = {
    name: props.profileData.getUserName(),
    firstname: props.profileData.getFirstName(),
    lastname: props.profileData.getLastName(),
    email: props.profileData.getEmail(),
    address1: props.profileData.getAddress1(),
    address2: props.profileData.getAddress2(),
    city: props.profileData.getCity(),
    region: props.profileData.getRegion(),
    country: props.profileData.getCountry(),
    zip: props.profileData.getZip(),
    dob: props.profileData.getDOB(),
    sex: props.profileData.getGender()
  };

  const formValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Public profile name is required'),
    firstname: Yup.string()
      .min(2, 'Given name is atleast 3 characters long')
      .max(100, 'Maximum 100 characters allowed')
      .matches(/^([a-zA-Z])*$/, 'Only English alphabet characters are allowed'),
    lastname: Yup.string()
      .min(2, 'Family name is atleast 3 characters long')
      .max(100, 'Maximum 100 characters allowed')
      .matches(/^([a-zA-Z])*$/, 'Only English alphabet characters are allowed'),
    email: Yup.string()
      .email('Email is invalid')
      .required('Email is required'),
    sex: Yup.number(),
    dob: Yup.date(),
    address1: Yup.string()
      .min(3, 'Address is atleast 3 characters long')
      .max(255, 'Maximum 255 characters allowed'),
    address2: Yup.string()
      .min(3, 'Address is atleast 3 characters long')
      .max(255, 'Maximum 255 characters allowed'),
    city: Yup.string()
      .min(3, 'City is atleast 3 characters long')
      .max(255, 'Maximum 255 characters allowed')
      .required('City is required'),
    region: Yup.string()
      .max(255, 'Maximum 255 characters allowed')
      .required('State or Region is required'),
    country: Yup.string()
      .min(2, 'Country is atleast 2 characters long')
      .max(255, 'Maximum 255 characters allowed')
      .required('Country is required'),
    zip: Yup.string()
      .min(3, 'Zip or PIN code is atleast 3 characters long')
      .max(255, 'Maximum 255 characters allowed')
  });

  const form = ({ values, errors, status, touched, handleChange }) => {
    return (
      <FormikForm className='form'>
        <Row form>
          <Col md={12}>
            <Label for='firstname'>Name</Label>
          </Col>
          <Col md={6}>
            <FormGroup >
              <FormikField type='text' name='firstname'
                className={'form-control' + (errors.firstname && touched.firstname ? ' is-invalid ' : '')}
                placeholder='Given Name' />
              <ErrorMessage name='firstname' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup >
              <FormikField type='text' name='lastname'
                className={'form-control' + (errors.lastname && touched.lastname ? ' is-invalid ' : '')}
                placeholder='Family Name' />
              <ErrorMessage name='lastname' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={3}>
            <FontAwesomeIcon className='icon' icon={faUserTag} /> &nbsp;<Label for='name'>User name</Label>
          </Col>
          <Col md={8}>
            <FormGroup className='display-name'>
              <FormikField type='text' name='name'
                className={'form-control' + (errors.name && touched.name ? ' is-invalid ' : '')}
                placeholder='Display user name' />
              <ErrorMessage name='name' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={3}>
            <FontAwesomeIcon className='icon' icon={faAt} /> &nbsp;<Label for='email'>Email</Label>
          </Col>
          <Col md={8}>
            <FormGroup>
              <FormikField type='email' name='email'
                className={'form-control' + (errors.email && touched.email ? ' is-invalid ' : '')}
                placeholder='example@example.com' />
              <ErrorMessage name='email' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={3}>
            <FontAwesomeIcon className='icon' icon={faGenderless} /> &nbsp;
                    <Label for='sex'>Gender</Label>
          </Col>
          <Col md={4}>
            <FormGroup>
              <FormikField as='select' name='sex'
                className={'form-control' + (errors.sex && touched.sex ? ' is-invalid ' : '')}
                placeholder='Sex' >
                <option value='0'></option>
                <option value='2'>Male</option>
                <option value='3'>Female</option>
                <option value='4'>Other</option>
                <option value='1'>Prefer, not to say</option>
              </FormikField>
              <ErrorMessage name='sex' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={3}>
            <FontAwesomeIcon className='icon' icon={faCalendarDay} /> &nbsp;<Label for='dob'>Date of Birth</Label>
          </Col>
          <Col md={4}>
            <FormGroup>
              <FormikField type='date' name='dob'
                className={'form-control' + (errors.dob && touched.dob ? ' is-invalid ' : '')}
                placeholder='DOB' />
              <ErrorMessage name='dob' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={12}>
            <FontAwesomeIcon className='icon' icon={faAddressCard} /> &nbsp;<Label for='address1'>Address</Label>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup >
              <FormikField type='text' name='address1'
                className={'form-control' + (errors.address1 && touched.address1 ? ' is-invalid ' : '')}
                placeholder='House and/or Street address...' />
              <ErrorMessage name='address1' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup >
              <FormikField type='text' name='address2'
                className={'form-control' + (errors.address2 && touched.address2 ? ' is-invalid ' : '')}
                placeholder='County / District... ' />
              <ErrorMessage name='address2' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={8}>
            <FormGroup >
              <FormikField type='text' name='city'
                className={'form-control' + (errors.city && touched.city ? ' is-invalid ' : '')}
                placeholder='City' />
              <ErrorMessage name='city' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>

          <Col md={4}>
            <FormGroup >
              <FormikField type='text' name='zip'
                className={'form-control' + (errors.zip && touched.zip ? ' is-invalid ' : '')}
                placeholder='Zip' />
              <ErrorMessage name='zip' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col md={6}>
            <FormGroup >
              <CountryDropdown
                name='country'
                classes={'form-control' + (errors.country && touched.country ? ' is-invalid ' : '')}
                valueType='short'
                value={values.country}
                onChange={(_, value) => {
                  handleChange(value);
                }}
              />

              <ErrorMessage name='country' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>

          <Col md={6}>
            <FormGroup >
              <RegionDropdown
                name='region'
                classes={'form-control' + (errors.region && touched.region ? ' is-invalid ' : '')}
                countryValueType='short'
                country={values.country}
                value={values.region}
                onChange={(_, value) => {
                  handleChange(value)
                }}
              />
              <ErrorMessage name='region' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>

        </Row>

        <Row form>
          <FormGroup>
            <Button type='submit' color='primary'>Save</Button>
          </FormGroup>
        </Row>
      </FormikForm >
    );
  }

  return (
    <Container className='user-profile-form'>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormValues}
        validationSchema={formValidationSchema}
        onSubmit={props.onSubmit} >
        {form}
      </Formik>
    </Container >
  );
}

export default UserProfileForm;
