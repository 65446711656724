import React from 'react';
import './DisplayPad.css';
import { isUndefined, isNullOrUndefined, isNull } from 'util';
import { FilterKeyboardEvents, isModalDialogOpen } from './../../common';

class DisplayPad extends React.Component {
  constructor(props) {
    super(props);

    this.displayPadId = 'displayPadId';

    this.state = {
      position: 0,
      highlightClassName: 'highlightKey',
      keyPressed: null,
    };
  }

  componentDidMount = () => {
    if (typeof document !== undefined) {
      const padElement = document.getElementById(this.displayPadId);
      if (!isNullOrUndefined(padElement)) {
        document.addEventListener('keydown', this.handleKeydownEvent, false);
        document.addEventListener('keyup', this.handleKeyupEvent, false);
        document.addEventListener('focus', this.handleFocusIn, false);
        document.addEventListener('blur', this.handleFocusOut, false);
      }
    }
  }

  componentWillUnmount = () => {
    if (typeof document !== undefined) {
      const padElement = document.getElementById(this.displayPadId);
      if (!isNullOrUndefined(padElement)) {
        document.removeEventListener('keydown', this.handleKeydownEvent, false);
        document.removeEventListener('keyup', this.handleKeyupEvent, false);
        document.removeEventListener('focus', this.handleFocusIn, false);
        document.removeEventListener('blur', this.handleFocusOut, false);
      }
    }
  }

  handleFocusIn = (event) => {
    console.log('focusin')
    if (!isModalDialogOpen()) {
      console.log('focusin')
      //document.addEventListener('keydown', this.handleKeydownEvent, false);
      //document.addEventListener('keyup', this.handleKeyupEvent, false);
    }
  }

  handleFocusOut = (event) => {
    console.log('focusin')
    if (isModalDialogOpen()) {
      console.log('focusout')
      //document.removeEventListener('keydown', this.handleKeydownEvent, false);
      //document.removeEventListener('keyup', this.handleKeyupEvent, false);
    }
  }

  handleKeydownEvent = (event) => {
    if (!FilterKeyboardEvents(event)) {
      return;
    }

    event.preventDefault();

    if (this.isValidKeyPressed(event)) {
      this.setState((state, props) => ({
        position: state.position,
        highlightClassName: 'highlightValidKeyPressed',
        keyPressed: event
      }));

      if (!isUndefined(this.props.keypressSound)) {
        this.props.keypressSound(event);
      }
    } else {
      this.setState((state, props) => ({
        position: state.position,
        highlightClassName: 'highlightInvalidKeyPressed',
        keyPressed: event
      }));

      if (!isUndefined(this.props.keypressSound)) {
        this.props.keypressFailureSound(event);
      }
    }
  }

  handleKeyupEvent = (event) => {
    if (!FilterKeyboardEvents(event)) {
      return;
    }

    event.preventDefault();

    if (this.isValidKeyPressed(event)) {
      if (this.state.position <= this.displayText.length) {
        this.setState((state, props) => ({
          position: state.position + 1,
          highlightClassName: 'highlightKey',
          keyPressed: event
        }));
      }
    }

    if (this.state.position === this.displayText.length) {
      this.handleLessonComplete();
    }
  }

  handleLessonComplete = () => {
    if (!isUndefined(this.props.lessonComplete)) {
      this.props.lessonComplete();
    }

    this.setState((state, props) => ({
      position: 0,
      highlightClassName: 'highlightKey',
      keyPressed: null
    }));
  }

  setTitle = () => {
    this.currentTitle = document.title;
    document.title = this.props.data.lesson.name;
  }

  getDisplayText = () => {
    const repeatCount = this.props.data.lesson.text.repeat !== 0 ? this.props.data.lesson.text.repeat : 1;
    const text = this.props.data.lesson.text.data;
    this.displayText = text;
    for (let i = 1; i < repeatCount; ++i) {
      this.displayText += text
    }

    return this.displayText;
  }

  isValidKeyPressed = (event) => {
    this.setCurrentToView('currentKeyFocusId');

    if (event !== null) {
      return (event.key === this.displayText[this.state.position])
    }

    return false;
  }

  setCurrentToView = (id) => {
    const ele = document.getElementById(id);
    if (!isNullOrUndefined(ele)) {
      ele.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  highlighText = () => {
    const firstText = this.displayText.slice(0, this.state.position);
    const nextText = this.displayText.slice(this.state.position + 1, this.displayText.length);

    return (
      <div>
        {firstText}
        <span id='currentKeyFocusId' className={this.state.highlightClassName}>
          {
            this.displayText[this.state.position]
          }
        </span>
        {nextText}
      </div>
    );
  }

  render() {
    this.setTitle();
    this.getDisplayText();

    return (
      <div id={this.displayPadId} className='pad-area'>
        <span className='pad'>
          {
            this.highlighText()
          }
        </span>
      </div>
    );
  }
}

export default DisplayPad;