import 'bootstrap/dist/css/bootstrap.min.css';
import { isNullOrUndefined } from 'util';

export const HistoryConfig = {
  basename: process.env.PUBLIC_URL
}

/* eslint-disable no-fallthrough */
/**
 * Filter keyboard events for On Screen keyboard. Exception, disable lint warning case no-fallthrough
 * @param {*} event 
 */
export const FilterKeyboardEvents = (event) => {
  let filterKey = false; // by default filter keys

  //only handle the keyboard events that is required for typing 
  switch (event.code) {
    //first number row
    case 'Backquote':
    case 'Digit1':
    case 'Digit2':
    case 'Digit3':
    case 'Digit4':
    case 'Digit5':
    case 'Digit6':
    case 'Digit7':
    case 'Digit8':
    case 'Digit9':
    case 'Digit0':
    case 'Minus':
    case 'Equal':
    // second qwerty row
    case 'Tab':
    case 'KeyQ':
    case 'KeyW':
    case 'KeyE':
    case 'KeyR':
    case 'KeyT':
    case 'KeyY':
    case 'KeyU':
    case 'KeyI':
    case 'KeyO':
    case 'KeyP':
    case 'BracketLeft':
    case 'BracketRight':
    case 'Backslash':
    // 3rd row asdfg
    case 'CapsLock':
    case 'KeyA':
    case 'KeyS':
    case 'KeyD':
    case 'KeyF':
    case 'KeyG':
    case 'KeyH':
    case 'KeyJ':
    case 'KeyK':
    case 'KeyL':
    case 'Semicolon':
    case 'Quote':
    case 'Enter':
    //4th row zxcvb
    case 'ShiftLeft':
    case 'KeyZ':
    case 'KeyX':
    case 'KeyC':
    case 'KeyV':
    case 'KeyB':
    case 'KeyN':
    case 'KeyM':
    case 'Comma':
    case 'Period':
    case 'Slash':
    case 'ShiftRight':
    //5th space bar row
    case 'ControlLeft':
    case 'AltLeft':
    case 'Space':
    case 'AltRight':
    case 'ControlRight':
      filterKey = true;
      break;

    default:
    //do nothing
  }

  return filterKey;
}
/* eslint-enable no-fallthrough */

export const isModalDialogOpen = () => {
  let modalOpen = false;

  //custom id defined in UserDialog.js component
  const modalElement = document.getElementById('userModalDialog');
  if (!isNullOrUndefined(modalElement)) {
    modalOpen = true;
  }

  return modalOpen;
}