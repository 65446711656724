import './HelpForm.css'
import React from 'react';
import {
  Container, Row, Col,
  FormGroup, Button, Label, FormText,
} from 'reactstrap';
import * as Yup from 'yup';
import {
  Formik, Form as FormikForm, ErrorMessage, Field as FormikField
} from 'formik';
import { isNullOrUndefined } from 'util';
import { connect } from 'react-redux';

const HelpForm = (props) => {
  const initialFormValues = {
    email: (isNullOrUndefined(props.session.user.email) ? '' : props.session.user.email),
    helpCategory: (isNullOrUndefined(props.category) ? '' : props.category),
    message: ''
  };

  const formValidationSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required('Email is required. Please see our privacy policy'),
    helpCategory: Yup.string()
      .notOneOf([''], 'Select one option')
      .required('Please select'),
    message: Yup.string()
      .min(10, 'Minimum a sentence with atleast 10 characters required')
      .max(500, 'Maximum allowed is 500 characters')
      .required('Please describe your experience')
  });

  const form = ({ values, errors, status, touched }) => {
    return (
      <FormikForm>
        <Row form>
          <Col md={12}>
            <Label for='email'>Contact</Label>
            <FormikField type='text'
              name='email'
              className={'form-control' + (errors.email && touched.email ? ' is-invalid ' : '')}
              placeholder='example@email.com' />
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormikField as='select'
              name='helpCategory' id='helpCategory'
              className={'form-control' + (errors.helpCategory && touched.helpCategory ? ' is-invalid ' : '')}
              placeholder='How can we help?' >
              <option value='' defaultValue={''}>How can we help ?</option>
              <option value='login'>I can't login or use my email</option>
              <option value='accessibility'>I would like to report accessibility issues</option>
              <option value='error'>I get an error using product and like to report it</option>
              <option value='privacy'>I have privacy issues using the product</option>
              <option value='dmca'>I would like to report a DMCA Complaint</option>
              <option value='issue'>I have something else to report</option>
            </FormikField>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <FormikField as='textarea'
                name='message'
                rows='7'
                className={'form-control' + (errors.message && touched.message ? ' is-invalid ' : '')}
                placeholder='Please describe your experience and how can we make it better.' />
              <FormText color='muted'>Atleast 10 characters to the maximum of 500 characters</FormText>
              <ErrorMessage name='message' component='div' className='invalid-feedback' />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <Button type='submit' color='primary'>Submit</Button>
            </FormGroup>
          </Col>
        </Row>
      </FormikForm>
    );
  }

  return (
    <Container className='help-form'>
      <Formik
        initialValues={initialFormValues}
        validationSchema={formValidationSchema}
        onSubmit={props.onSubmit} >
        {form}
      </Formik>
    </Container >
  );
}

const mapStateToProps = ({ session }) => ({
  session: session
});

export default connect(mapStateToProps)(HelpForm);