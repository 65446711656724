import { ACTIVE_LESSON, NEXT_LESSON } from '../actions/types';

const intialState = {
  "lesson": {
    "id": 1,
    "name": "Lesson 1",
    "description": "Home row key - 5 times",
    "text": {
      "data": "asdfg",
      "repeat": 5
    },
    "stats": {
      "accuracy": 85,
      "words_per_min": 23,
      "rating": 3.5
    }
  }
};

const reducerLesson = (state = intialState, action) => {
  switch (action.type) {
    case ACTIVE_LESSON: {
      return {
        ...state,
        lesson: action.lesson
      };
    }

    case NEXT_LESSON: {
      return {
        ...state
      };
    }

    default: {
      return state;
    }
  }
};

export default reducerLesson;