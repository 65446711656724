import React from 'react';
import './App.css';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Switch, Redirect, Route } from 'react-router-dom';
import TypeWriter from '../TypeWriter/TypeWriter';
import KeyboardLessons from '../KeyboardLessons/KeyboardLessons';
import LoginPage from '../LoginPage/LoginPage';
import License from '../License/License';
import RefreshSession from '../RefreshSession/RefreshSession';
import UserProfile from '../UserProfile/UserProfile';
import Feedback from './../Feedback/Feedback';
import CustomerSupport from '../CustomerSupport/CustomerSupport';
import { Container, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { sessionService } from 'redux-react-session';

const App = (props) => {

  if (!props.session.checked) {
    sessionService.loadSession()
      .then((currentSession) => {
        console.log('CurrentSesson : ')
        console.log(currentSession);
      })
      .catch(() => {
        console.log('Session not found');
      });
  }

  return (
    <Container className="App" >
      <Row className='width-100'>
        <Col md={12}>
          <Header />
        </Col>
      </Row>
      <Row className='width-100 height-100'>
        <Col md={12}>
          <main className='main'>
            <Switch>
              <Route path='/lessons' component={KeyboardLessons} />
              <Route exact path='/writer' component={TypeWriter} />
              <Route exact path='/login' component={LoginPage} />
              <Route exact path='/profile'>
                <UserProfile activeTab='profile' />
              </Route>
              <Route exact path='/contact'>
                <CustomerSupport tab='feedback' />
              </Route>
              <Route exact path='/terms' component={License} />
              <Route exact path='/policy' component={License} />
              <Route exact path='/licenses' component={License} />
              <Route exact path='/redirect' component={RefreshSession} />
              <Redirect to='/writer' />
            </Switch>
          </main>
        </Col>
      </Row>
      <Row className='width-100'>
        <Col md={12}>
          <Footer />
        </Col>
      </Row>
      <Feedback vertical={true} />
    </Container>
  );
}

const mapStateToProps = (({ session }) => ({
  session: session
}));

export default connect(mapStateToProps)(App);
