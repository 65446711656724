import { ACTIVE_LESSON, NEXT_LESSON } from './types';

export const activeLesson = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ACTIVE_LESSON,
      lesson: payload
    });
  }
}

export const nextLesson = (payload) => {
  return (dispatch) => {
    dispatch({
      type: NEXT_LESSON,
      lesson: payload
    });
  }
}