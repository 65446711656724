import { isNullOrUndefined } from 'util';

class UserProfileData {
  constructor(data) {
    this.data = data;
  }

  getUserId = () => {
    return this.data.id;
  }

  getUserName = () => {
    let value = this.data.displayname;
    if (isNullOrUndefined(value)) {
      value = '';
    }
    return value;
  }

  getFirstName = () => {
    let value = this.data.givenname;
    if (isNullOrUndefined(value)) {
      value = '';
    }
    return value;
  }

  getLastName = () => {
    let value = this.data.familyname;
    if (isNullOrUndefined(value)) {
      value = '';
    }
    return value;
  }

  getEmail = () => {
    let value = this.data.email;
    if (isNullOrUndefined(value)) {
      value = '';
    }
    return value;
  }

  getEmailVerified = () => {
    let value = this.data.emailverified;
    if (isNullOrUndefined(value)) {
      value = 0;
    } else {
      value = parseInt(value);
    }

    return (value !== 0 ? true : false);
  }

  getAddress1 = () => {
    let value = this.data.address;
    if (isNullOrUndefined(value)) {
      value = '';
    }
    return value;
  }

  getAddress2 = () => {
    let value = this.data.address2;
    if (isNullOrUndefined(value)) {
      value = '';
    }
    return value;
  }

  getCity = () => {
    let value = this.data.city;
    if (isNullOrUndefined(value)) {
      value = '';
    }
    return value;
  }

  getRegion = () => {
    let value = this.data.region;
    if (isNullOrUndefined(value)) {
      value = '';
    }
    return value;
  }

  getCountry = () => {
    let value = this.data.country;
    if (isNullOrUndefined(value)) {
      value = '';
    }
    return value;
  }

  getZip = () => {
    let value = this.data.zip;
    if (isNullOrUndefined(value)) {
      value = '';
    }
    return value;
  }

  getGender = () => {
    let value = this.data.sex;
    if (isNullOrUndefined(value)) {
      value = 0;
    } else {
      value = parseInt(value, 10);
    }

    return value;
  }

  getDOB = () => {
    let value = this.data.dob;
    if (!isNullOrUndefined(value)) {
      value = new Date(Date.parse(value));
      //ISO format date, without time
      value = value.toISOString();
      value = value.substr(0, value.indexOf('T'));
    } else {
      value = ''
    }

    return value;
  }

  setUserData = (value) => {
    this.data = { ...this.data, ...value };
  }
}

export default UserProfileData;