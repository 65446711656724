import React from 'react';
import './Keyboard.css';
import { isUndefined, isNullOrUndefined } from 'util';
import Key from './Key';
import { FilterKeyboardEvents, isModalDialogOpen } from './../../common';

class Keyboard extends React.Component {
  constructor(props) {
    super(props);

    this.capsLockOn = false;
    this.onscreenKeyboardId = 'onscreenKeyboardId';

    this.state = {
      keyPressed: null,
      capsLockOn: this.capsLockOn
    };
  }

  componentDidMount = () => {
    if (typeof document !== undefined) {
      const keyboardElement = document.getElementById(this.onscreenKeyboardId);
      if (!isNullOrUndefined(keyboardElement)) {
        document.addEventListener('keydown', this.handleKeydownEvent, false);
        document.addEventListener('keyup', this.handleKeyupEvent, false);

        document.addEventListener('shown.bs.modal', this.handleFocusIn, false);
        document.addEventListener('hidden.bs.modal', this.handleFocusOut, false);
      }
    }
  }

  componentWillUnmount = () => {
    if (typeof document !== undefined) {
      const keyboardElement = document.getElementById(this.onscreenKeyboardId);
      if (!isNullOrUndefined(keyboardElement)) {
        document.removeEventListener('keydown', this.handleKeydownEvent, false);
        document.removeEventListener('keyup', this.handleKeyupEvent, false);

        document.removeEventListener('shown.bs.modal', this.handleFocusIn, false);
        document.removeEventListener('hidden.bs.modal', this.handleFocusOut, false);
      }
    }
  }

  handleFocusIn = (event) => {
    console.log('modal open');
    if (!isModalDialogOpen()) {
      //document.addEventListener('keydown', this.handleKeydownEvent, false);
      //document.addEventListener('keyup', this.handleKeyupEvent, false);
    }
  }

  handleFocusOut = (event) => {
    console.log('modal closed');
    if (isModalDialogOpen()) {
      //document.removeEventListener('keydown', this.handleKeydownEvent, false);
      //document.removeEventListener('keyup', this.handleKeyupEvent, false);
    }
  }

  handleKeydownEvent = (event) => {
    if (!FilterKeyboardEvents(event)) {
      return;
    }

    event.preventDefault();

    if (event.code === 'CapsLock') {
      this.capsLockOn = !this.capsLockOn;
    }

    this.setState({
      keyPressed: event,
      capsLockOn: this.capsLockOn
    });

    if (!isUndefined(this.props.onKeyPressed)) {
      this.props.onKeyPressed(event);
    }
  }

  handleKeyupEvent = (event) => {
    if (!FilterKeyboardEvents(event)) {
      return;
    }

    event.preventDefault();
    this.setState({ keyPressed: null });
  }



  render() {
    return (
      <div id={this.onscreenKeyboardId} className='keyboard row btn-group' role='group'>
        <div className='row'>
          <Key className='pinky-finger' char='`' shift='~' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Backquote' : false} />
          <Key className='pinky-finger' char='1' shift='!' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Digit1' : false} />
          <Key className='ring-finger' char='2' shift='@' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Digit2' : false} />
          <Key className='middle-finger' char='3' shift='#' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Digit3' : false} />
          <Key className='index-finger' char='4' shift='$' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Digit4' : false} />
          <Key className='index-finger' char='5' shift='%' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Digit5' : false} />
          <Key className='right-index-finger' char='6' shift='^' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Digit6' : false} />
          <Key className='right-index-finger' char='7' shift='&' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Digit7' : false} />
          <Key className='right-middle-finger' char='8' shift='*' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Digit8' : false} />
          <Key className='right-ring-finger' char='9' shift='(' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Digit9' : false} />
          <Key className='right-pinky-finger' char='0' shift=')' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Digit0' : false} />
          <Key className='right-pinky-finger' char='-' shift='_' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Minus' : false} />
          <Key className='right-pinky-finger' char='=' shift='+' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Equal' : false} />
          <Key className='key-back-space right-pinky-finger' char='Backspace' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Backspace' : false} />
        </div>
        <div className='row'>
          <Key className='key-tab pinky-finger' char='Tab' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Tab' : false} />
          <Key className='pinky-finger' char='Q' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyQ' : false} />
          <Key className='ring-finger' char='W' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyW' : false} />
          <Key className='middle-finger' char='E' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyE' : false} />
          <Key className='index-finger' char='R' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyR' : false} />
          <Key className='index-finger' char='T' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyT' : false} />
          <Key className='right-index-finger' char='Y' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyY' : false} />
          <Key className='right-index-finger' char='U' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyU' : false} />
          <Key className='right-middle-finger' char='I' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyI' : false} />
          <Key className='right-ring-finger' char='O' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyO' : false} />
          <Key className='right-pinky-finger' char='P' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyP' : false} />
          <Key className='right-pinky-finger' char='[' shift='{' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'BracketLeft' : false} />
          <Key className='right-pinky-finger' char=']' shift='}' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'BracketRight' : false} />
          <Key className='key-front-slash right-pinky-finger' char='\' shift='|' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Backslash' : false} />
        </div>
        <div className='row'>
          <Key className='key-caps-lock pinky-finger' char='Caps Lock' sticky={this.state.capsLockOn} pressed={this.state.keyPressed ? this.state.keyPressed.code === 'CapsLock' : false} />
          <Key className='pinky-finger' char='A' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyA' : false} />
          <Key className='ring-finger' char='S' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyS' : false} />
          <Key className='middle-finger' char='D' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyD' : false} />
          <Key className='index-finger' char='F' guide={true} pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyF' : false} />
          <Key className='index-finger' char='G' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyG' : false} />
          <Key className='right-index-finger' char='H' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyH' : false} />
          <Key className='right-index-finger' char='J' guide={true} pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyJ' : false} />
          <Key className='right-middle-finger' char='K' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyK' : false} />
          <Key className='right-ring-finger' char='L' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyL' : false} />
          <Key className='right-pinky-finger' char=';' shift=':' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Semicolon' : false} />
          <Key className='right-pinky-finger' char="'" shift='"' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Quote' : false} />
          <Key className='key-enter right-pinky-finger' char='Enter' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Enter' : false} />
        </div>
        <div className='row'>
          <Key className='key-shift pinky-finger' char='Shift' pressed={this.state.keyPressed !== null ? this.state.keyPressed.code === 'ShiftLeft' : false} />
          <Key className='pinky-finger' char='Z' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyZ' : false} />
          <Key className='ring-finger' char='X' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyX' : false} />
          <Key className='middle-finger' char='C' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyC' : false} />
          <Key className='index-finger' char='V' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyV' : false} />
          <Key className='index-finger' char='B' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyB' : false} />
          <Key className='right-index-finger' char='N' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyN' : false} />
          <Key className='right-index-finger' char='M' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'KeyM' : false} />
          <Key className='right-middle-finger' char=',' shift='<' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Comma' : false} />
          <Key className='right-ring-finger' char='.' shift='>' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Period' : false} />
          <Key className='right-pinky-finger' char='/' shift='?' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Slash' : false} />
          <Key className='key-shift right-pinky-finger' char='Shift' pressed={this.state.keyPressed !== null ? this.state.keyPressed.code === 'ShiftRight' : false} />
        </div>
        <div className='row'>
          <Key className='key-ctrl pinky-finger' char='Ctrl' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'ControlLeft' : false} />
          <Key className='pinky-finger' char='' />
          <Key className='key-alt ring-finger' char='Alt' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'AltLeft' : false} />
          <Key className='key-space thumb' char='Space' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'Space' : false} />
          <Key className='key-alt right-ring-finger' char='Alt' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'AltRight' : false} />
          <Key className='right-pinky-finger' char='' />
          <Key className='key-ctrl right-pinky-finger' char='Ctrl' pressed={this.state.keyPressed ? this.state.keyPressed.code === 'ControlRight' : false} />
        </div>
      </div>
    );
  }
}

export default Keyboard;