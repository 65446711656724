import { USER_STATUS } from '../actions/types';

//Initial state, no user id information available 
const initialState = {
  user: {
    id: '',
    authprovider: '',
    authproviderid: ''
  }
}

const reducerUserStatus = (state = initialState, action) => {
  switch (action.type) {
    case USER_STATUS: {
      return ({
        ...state,
        user: action.user
      })
    }

    default: {
      return ({
        ...state
      });
    }
  };
}

export default reducerUserStatus;