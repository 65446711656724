import React, { useState, useEffect } from 'react';
import './KeyboardLessons.css';
import {
  Row, Col,
  Card, CardBody, CardHeader, Collapse
} from 'reactstrap';
import { isUndefined } from 'util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import KeyboardLessonItem from '../KeyboardLessonItem/KeyboardLessonItem';

const data = require('../../data/lessons-data.json');

const ListData = (props) => {
  const [collapse, setCollapse] = useState('');
  const onToggle = (e) => {
    if (collapse !== e.target.dataset.event) {
      setCollapse(e.target.dataset.event);
    } else {
      setCollapse('');
    }
  }

  const setActive = (data) => {
    Object.entries(data).map(([key, value]) =>
      value.active ? setCollapse(key) : null
    )

  }

  useEffect(() => {
    setActive(props.data)
  }, [props.data]);

  return (
    <div className='list-data'>
      {
        Object.entries(props.data).map(([key, value]) =>
          <Card
            className='card1'
            inverse={true}
            color={value.active ? 'primary' : 'info'}
            key={key}
          >
            <CardHeader>
              <Row>
                <Col onClick={onToggle} data-event={key}>
                  {!isUndefined(value.name) ? value.name : key} &nbsp;
                </Col>
                <Col className='align-right' onClick={onToggle} data-event={key}>
                  <FontAwesomeIcon icon={collapse === key ? faChevronUp : faChevronDown} />
                </Col>
              </Row>
            </CardHeader>
            <Collapse isOpen={collapse === key}>
              <CardBody className='card-body-active'>
                <KeyboardLessonItem data={value.lessons} />
              </CardBody>
            </Collapse>
          </Card>
        )
      }
    </div>
  );
}

const KeyboardLessons = (props) => {
  return (
    <div className='lessons'>
      <ListData data={data} />
    </div>
  );
}

export default KeyboardLessons;