import './FeedbackRating.css'
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Nav, NavItem,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGrinStars, faGrin, faFrown, faMeh, faSmile
} from '@fortawesome/free-solid-svg-icons';
//import UserDialog from './../UserDialog/UserDialog';
//import CustomerSupport from './../CustomerSupport/CustomerSupport';
import { isNullOrUndefined } from 'util';
import API from '../../common/api';

const FeedbackRating = (props) => {
  const history = useHistory();

  //const [showDialog, setShowDialog] = useState(false);
  //const [feedbackRating, setFeedbackRating] = useState(0);

  /**
   * Close the Support overlay dialog options
   */
  /*
    const onDialogClose = () => {
    setShowDialog(false);
  }
  */

  /**
   * Process the feedback processing
   * @param {*} msg 
   * @param {*} value 
   */
  const handleFeedback = (msg, value) => {
    const fields = {
      form: 'quick',
      message: msg,
      rating: value
    }

    //setFeedbackRating(value);

    if (!isNullOrUndefined(props.supportDialog)) {
      //setShowDialog(true);
    } else {
      API.ProcessFeedback(fields);
    }

    if (!isNullOrUndefined(props.hideCallback)) {
      props.hideCallback(props.id);
    }

    history.push('/contact?tab=feedback');
  }

  /*
      <UserDialog className='register-dialog'
          visible={showDialog}
          title={<>
            <FontAwesomeIcon className='icon' icon={faCommentDots} /> &nbsp; <b>What's on your mind?</b>
          </>}
          onClose={onDialogClose} >
          <CustomerSupport tab='feedback' rating={feedbackRating} email={'example1@jj.com'} callback={onDialogClose} />
        </UserDialog>
  */

  return (
    <div>
      <div id={props.id} className='feedback-rating'>
        <Nav vertical={props.vertical}>
          <NavItem>
            <FontAwesomeIcon className='awesome circle' icon={faGrinStars} onClick={() => { handleFeedback('awesome', 5) }} />
          </NavItem>
          <NavItem>
            <FontAwesomeIcon className='good circle' icon={faGrin} onClick={() => { handleFeedback('good', 4) }} />
          </NavItem>
          <NavItem>
            <FontAwesomeIcon className='ok circle' icon={faSmile} onClick={() => { handleFeedback('ok', 3) }} />
          </NavItem>
          <NavItem>
            <FontAwesomeIcon className='improve circle' icon={faMeh} onClick={() => { handleFeedback('improve', 2) }} />
          </NavItem>
          <NavItem>
            <FontAwesomeIcon className='attention circle' icon={faFrown} onClick={() => { handleFeedback('attention', 1) }} />
          </NavItem>
        </Nav>
      </div>
    </div>
  );
}

export default FeedbackRating;