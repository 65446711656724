
import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './components/reducers';
import thunk from 'redux-thunk';
import { sessionService } from 'redux-react-session';
import API from './common/api';

const middleware = [thunk];

const store = createStore(
  rootReducer,
  {},
  compose(
    applyMiddleware(...middleware),
    typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);
/*
const validateSession = (session) => {
  console.log('Validate Session')
  console.log(session);
  API.verifyCurrentSession((error, response) => {
    if (error) {
      return false;
    }

    if (response.status === 200) {
      return true;
    }
  });

  return false;
}

const sessionOptions = {
  refreshOnCheckAuth: true,
  redirectPath: '/login',
  driver: 'COOKIES',
  validateSession
};
*/

sessionService.initSessionService(store)
  .then(() => {
    console.log("React session is ready and refreshed from local storage");
  })
  .catch(() => {
    console.log("React session is ready");
    API.verifyCurrentSession((error, response) => {
      console.log(response);
      if (error) {
        return false;
      }

      if (response.status === 200) {
        return true;
      }
    });
  });

export default store;
