import React from 'react';
import './UserDialog.css';
import {
  Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { isUndefined } from 'util';

const UserDialog = (props) => {
  const {
    visible,
    className,
    title,
    footer,
    onClose,
  } = props;

  const handleCloseDialog = () => {
    if (!isUndefined(onClose)) {
      onClose();
    }
  }

  const closeButton = <button className='close' onClick={handleCloseDialog}>&times;</button>

  return (
    <div className={className}>
      <Modal id='userModalDialog' isOpen={visible}>
        <ModalHeader close={closeButton}>
          {title}
        </ModalHeader>
        <ModalBody className='content'>
          {
            props.children
          }
        </ModalBody>
        {
          footer ? <ModalFooter>footer</ModalFooter> : <></>
        }
      </Modal>
    </div >
  );
}

export default UserDialog;