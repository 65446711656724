import React from 'react';
import './KeyboardLessonItem.css';
import {
  Button
} from 'reactstrap';
import { isUndefined } from 'util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faStar } from '@fortawesome/free-solid-svg-icons';
import Rating from 'react-rating';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { activeLesson } from './../actions/actionLesson';

const KeyboardLessonItem = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isStatsAvailable = (value) => {
    return (!isUndefined(value.stats));
  }

  const isDisabled = (value) => {
    if (value.active) {
      return false;
    }

    //if stats are available, i.e user has practices the lesson before
    //show stats
    if (isStatsAvailable(value)) {
      return false;
    }

    if (!isUndefined(value.locked)) {
      return value.locked;
    }

    return true;
  }

  const lockItem = (value) => {
    return isDisabled(value) ? <FontAwesomeIcon className='icon-spacing small' icon={faLock} />
      : <></>
  }

  const itemInfo = (value) => {
    const starIcon = () => {
      return (
        <FontAwesomeIcon className='icon-highlight small' icon={faStar} />
      );
    }

    const starEmpty = () => {
      return (
        <FontAwesomeIcon className='icon-normal small' icon={faStar} />
      );
    }

    return (
      <>
        <div>
          {lockItem(value)}
          {value.name}
          <div>
            {
              isStatsAvailable(value) ? (
                <Rating readonly
                  initialRating={isUndefined(value.stats) ? 0 : value.stats.rating}
                  emptySymbol={starEmpty()}
                  fullSymbol={starIcon()}
                />)
                : <></>
            }
          </div>
        </div>
        <div className='title-desc'>
          {value.description}
        </div>
      </>
    );
  }

  const onLessonItemPressed = value => (event) => {
    dispatch(activeLesson(value));
    history.push('/writer');
  }

  return (
    <div className='list-items'>
      {
        Object.entries(props.data).map(([key, value]) => (
          <Button
            key={key}
            className='button1'
            tag='button' action='true'
            color={value.active ? 'primary' : 'info'}
            disabled={isDisabled(value)}
            onClick={onLessonItemPressed(value)}
          >
            {itemInfo(value)}
          </Button>
        ))
      }
    </div>
  );
}

export default KeyboardLessonItem;