import { isNullOrUndefined } from "util";

const getAPIEndpoint = (endpoint) => {
  let apiUrl = '';

  if (isNullOrUndefined(process.env.REACT_APP_API_URL)) {
    console.log('API server root is not defined, aborting api call');
    return null;
  } else {
    apiUrl = process.env.REACT_APP_API_URL + endpoint;
  }

  return apiUrl;
}

/**
 * POST - Login or Register request 
 * @param {*} loginParams 
 * @param {*} callback 
 */
const ProcessLogin = (loginParams, callback) => {
  const apiUrl = getAPIEndpoint(isNullOrUndefined(loginParams.displayname) ? '/auth/local' : '/register');
  if (apiUrl === null) {
    return;
  }

  const requestOptions = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(loginParams)
  };

  try {
    fetch(apiUrl, requestOptions)
      .then(async (response) => {
        const responseData = await response.json();
        responseData.status = response.status;
        callback(null, responseData);
      });
  } catch (exception) {
    callback(new Error('Host not reachable or network issue'), null);
  }
}

const ProcessFeedback = (fields) => {
  const apiUrl = getAPIEndpoint('/feedback');
  if (apiUrl === null) {
    return;
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify(fields)
  };

  fetch(apiUrl, requestOptions)
    .then(async (response) => {
      const responseData = await response.json();
      if (response.status !== 200) {
        console.log('Server is having issues : ' + JSON.stringify(responseData));
      }
    });
}

const HandleLogout = (callback) => {
  let apiUrl = getAPIEndpoint('/logout');
  if (apiUrl === null) {
    return callback(new Error('API endpoint not available'), null);
  }

  try {
    fetch(apiUrl)
      .then(async (response) => {
        const responseData = await response.json();
        responseData.status = response.status;
        callback(null, responseData)
      })
      .catch(() => {
        callback(new Error('Server request failed'), null);
      });
  } catch (exception) {
    //assume - 500, internal server error
    callback(new Error('Host not reachable or network issue'), null);
  }
}

/**
 * GET user data given a profile id
 */
const getUserData = (profileId, callback) => {
  if (isNullOrUndefined(profileId) || profileId === '') {
    return callback(new Error('Invalid profile id'), null);
  }

  const apiUrl = getAPIEndpoint('/api/users/v1/' + profileId);
  if (apiUrl === null) {
    return callback(new Error('API endpoint not available'), null);
  }

  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  }

  try {
    fetch(apiUrl, requestOptions)
      .then(async (response) => {
        const responseData = await response.json();
        responseData.status = response.status;
        callback(null, responseData)
      });
  } catch (exception) {
    //assume - 500, internal server error
    callback(new Error('Host not reachable or network issue'), null);
  }
}

/**
 * Verify the current session by requesting /auth endpoint
 * @param {*} callback 
 */
const verifyCurrentSession = (callback) => {
  const apiUrl = getAPIEndpoint('/auth');
  if (apiUrl === null) {
    return callback(new Error('API endpoint not available'), null);
  }

  const requestOptions = {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  try {
    fetch(apiUrl, requestOptions)
      .then(async (response) => {
        const responseData = await response.json();
        console.log(responseData);
        responseData.status = response.status;

        callback(null, responseData);
      })
      .catch(() => {
        callback(new Error('Server request failed'), null);
      });
  } catch (exception) {
    callback(new Error('Host not reachable or network issue'), null);
  }
}

const API = {
  getAPIEndpoint,
  ProcessFeedback,
  ProcessLogin,
  HandleLogout,
  getUserData,
  verifyCurrentSession
}

export default API;