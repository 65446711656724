import './CustomerSupport.css'
import React, { useState } from 'react';
import classNames from 'classnames';
import {
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import { faComment, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeedbackForm from './FeedbackForm';
import HelpForm from './HelpForm';
import API from '../../common/api';
import { isNullOrUndefined } from 'util';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';

const CustomerSupport = (props) => {
  const [activeTab, setActiveTab] = useState(isNullOrUndefined(props.tab) ? 'feedback' : props.tab);

  return (
    <Container className='customer-support-container'>
      <Row>
        <Col md={12} className='support-tab-area'>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === 'feedback' })}
                onClick={() => { setActiveTab('feedback') }} >
                <FontAwesomeIcon className='icon' icon={faComment} />  Feedback
              </NavLink>
            </NavItem>
            {
              props.session.authenticated ?
                <NavItem>
                  <NavLink
                    className={classNames({ active: activeTab === 'help' })}
                    onClick={() => { setActiveTab('help') }} >
                    <FontAwesomeIcon className='icon' icon={faQuestionCircle} /> Help
                </NavLink>
                </NavItem>
                : <></>
            }
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId='feedback'>
              <FeedbackForm rating={props.rating} onSubmit={(fields) => {
                fields.form = 'feedback';
                API.ProcessFeedback(fields);
                if (!isNullOrUndefined(props.callback)) {
                  props.callback();
                }
              }} />
            </TabPane>

            <TabPane tabId='help'>
              <HelpForm email={props.email} category={'dmca'} onSubmit={(fields) => {
                fields.form = 'help';
                API.ProcessFeedback(fields);
                if (!isNullOrUndefined(props.callback)) {
                  props.callback();
                }
              }} />
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
}

const mapStateToProps = ({ session }) => ({
  session: session
});

export default connect(mapStateToProps)(CustomerSupport);