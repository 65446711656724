import './../../common';
import './LoginPage.css';
import React, { useState, useEffect } from 'react';
import { sessionService } from 'redux-react-session';

import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  FormGroup, FormText, FormFeedback,
  Label, Input, Alert,
  Container, Row, Col
} from 'reactstrap';
import { Formik, ErrorMessage, Field as FormikField, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import {
  GithubLoginButton, LinkedInLoginButton, MicrosoftLoginButton,
  GoogleLoginButton, FacebookLoginButton, TwitterLoginButton
} from 'react-social-login-buttons';
import { connect, useDispatch } from 'react-redux';
import { userStatus } from './../actions/actionStatus';
import UserDialog from '../UserDialog/UserDialog';
import { useAlert } from 'react-alert';
import API from './../../common/api';

const LoginPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();

  const [rememberMe, setOptionRememberMe] = useState(false);
  const [showPassword, setOptionShowPassword] = useState(false);
  const [loginError, showLoginError] = useState('');
  const [registerOverlay, showRegistrationOverlay] = useState(false);

  const dismissAlert = () => {
    showLoginError('');
  }

  /*
    Parse query params to find error information 
  */
  let query = props.history.location.search;
  if (query.length > 0) {
    query = query.substr(1);
    const i = query.indexOf('=');
    if (i !== -1) {
      const param = query.substr(0, i);
      if (param === 'error') {
        try {
          const value = atob(query.substr(i + 1));
          const error = JSON.parse(value);
          alert.error(error.message);
        } catch (e) {
        }
      }
    }
  }

  useEffect(() => {
    if (props.userStatus.user.id !== '') {
      //user already logged in, re-direct to home or profile page
      if (props.userStatus.user.created === false) {
        history.push('/');
      } else if (props.userStatus.user.created === true) {
        history.push('/profile');
      }
    }
  }, [props.userStatus, history]);

  /**
   * Login or register request
   * @param {*} fields 
   */
  const handleLoginSubmit = (fields) => {
    dismissAlert();

    const loginParams = {
      authprovider: 'local',
      authproviderid: fields.email,
      authtoken: fields.secretkey7,
      displayname: fields.name
    };

    API.ProcessLogin(loginParams, (error, responseData) => {
      if (error) {
        showLoginError(error.message);
      }

      dispatch(userStatus(responseData.user));
      sessionService.saveUser(responseData.user);
      sessionService.saveSession(responseData.user);
      if (responseData.status === 200) {
        history.push('/');
      } else if (responseData.status === 201) {
        history.push('/profile');
      }
    });
  }

  const handleRegisterButton = (event) => {
    event.preventDefault();
    showRegistrationOverlay(true);
  }

  const handleRegistrationDialogClose = (event) => {
    showRegistrationOverlay(false);
  }

  const loginErrorAlert = () => {
    return (
      <>
        {
          loginError ?
            <Alert className='alert' color='danger'>
              {loginError}
            </Alert>
            : <></>
        }
      </>
    );
  }

  const loginForm = () => {
    return (
      <Formik
        initialValues={{
          email: '',
          secretkey7: ''
        }}
        validationSchema={
          Yup.object().shape({
            email: Yup.string()
              .email('Email is invalid')
              .required('Email is required'),
            secretkey7: Yup.string()
              .min(8, 'Password length must be between 8 - 36 characters with a capital letter, 1 small letter, 1 number and 1 of the allowed special characters !@#$%^&*()')
              .max(36, 'Password length must be between 8 - 36 characters with a capital letter, 1 small letter, 1 number and 1 of the allowed special characters !@#$%^&*()')
              .required('Password is required'),
          })
        }
        onSubmit={handleLoginSubmit}
      >
        {({ errors, status, touched }) =>
          (
            <Container>
              <FormikForm className='login-email' >
                <Row>
                  <Col md={12}>
                    <FormGroup>
                      <Label for='email' className='login-with-email'>Login with Email</Label>
                      <FormikField type='email' name='email'
                        className={'form-control form-field-email' + (errors.email && touched.email ? ' is-invalid' : '')}
                        placeholder='example@example.com' />
                      <ErrorMessage name='email' component='div' className='invalid-feedback' />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <FormikField type={showPassword ? 'text' : 'password'}
                        name='secretkey7'
                        className={'form-control form-field-pwd' + (errors.secretkey7 && touched.secretkey7 ? ' is-invalid' : '')}
                        placeholder='Enter password' />
                      <ErrorMessage name='secretkey7' component='div' className='invalid-feedback' />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup inline>
                      <input type='checkbox' defaultChecked
                        onClick={(event) => { setOptionRememberMe(!rememberMe) }} />
                        &nbsp;Remember me &nbsp;&nbsp;

                      <input type='checkbox'
                        onClick={(event) => { setOptionShowPassword(!showPassword) }} />
                        &nbsp;Show Password
                </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Link to=''>Forgot password ?</Link>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className='text-align-center'>
                      <Button type='submit' className='login-button' color='primary'>Login</Button>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <FormText color='muted'>Register with your email</FormText>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup >
                      <Button className='login-button' color='secondary' onClick={handleRegisterButton}>Register</Button>
                    </FormGroup>
                  </Col>
                </Row>

              </FormikForm >
            </Container>
          )}
      </Formik>
    );
  }

  const registrationForm = () => {
    return (
      <Formik
        initialValues={{
          name: '',
          email: '',
          secretkey7: '',
          confirmSecretkey7: '',
          acceptTerms: false
        }}
        validationSchema={
          Yup.object().shape({
            name: Yup.string()
              .required('Name required'),
            email: Yup.string()
              .email('Email is invalid')
              .required('Email is required'),
            secretkey7: Yup.string()
              .min(8, 'Password length must be between 8 - 36 characters with a capital letter, 1 small letter, 1 number and 1 of the allowed special characters !@#$%^&*()')
              .max(36, 'Password length must be between 8 - 36 characters with a capital letter, 1 small letter, 1 number and 1 of the allowed special characters !@#$%^&*()')
              .required('Password is required'),
            confirmSecretkey7: Yup.string()
              .oneOf([Yup.ref('secretkey7'), null], 'Password does not match')
              .required('Confirm password is required'),
            acceptTerms: Yup.bool()
              .oneOf([true], 'Please read and accept Terms and conditions of service usage')
          })
        }
        onSubmit={handleLoginSubmit}
      >
        {({ errors, status, touched }) => (
          <FormikForm className='login-email'>
            <FormGroup>
              <FormikField type='text' name='name'
                className={'form-control' + (errors.name && touched.name ? ' is-invalid ' : '')}
                placeholder='Display Name' />
              <FormFeedback>Enter name</FormFeedback>
            </FormGroup>
            <FormGroup>
              <FormikField type='email' name='email'
                className={'form-control' + (errors.email && touched.email ? ' is-invalid ' : '')}
                placeholder='example@example.com' />
              <ErrorMessage name='email' component='div' className='invalid-feedback' />
            </FormGroup>
            <FormGroup>
              <FormikField type={showPassword ? 'text' : 'password'}
                name='secretkey7'
                className={'form-control' + (errors.secretkey7 && touched.secretkey7 ? ' is-invalid ' : '')}
                placeholder='Enter password' />
              <ErrorMessage name='secretkey7' component='div' className='invalid-feedback' />
            </FormGroup>
            <FormGroup>
              <FormikField type='password'
                name='confirmSecretkey7'
                className={'form-control' + (errors.confirmSecretkey7 && touched.confirmSecretkey7 ? ' is-invalid ' : '')}
                placeholder='Confirm password' />
              <ErrorMessage name='confirmSecretkey7' component='div' className='invalid-feedback' />
            </FormGroup>
            <FormGroup check inline>
              <Input type='checkbox' defaultChecked
                onClick={(event) => { setOptionRememberMe(!rememberMe) }} />
                &nbsp;Remember me&nbsp;&nbsp;
              <Input type='checkbox'
                onClick={(event) => { setOptionShowPassword(!showPassword) }} />
              &nbsp;Show Password
            </FormGroup>
            <FormGroup check>
              <FormikField type='checkbox' name='acceptTerms'
                className={'form-check-input' + (errors.acceptTerms && touched.acceptTerms ? ' is-invalid ' : '')} />
                I agree to the <a href='/terms' target='_blank' rel='noreferrer noopener'>Terms and conditions</a> of the service usage.
              <ErrorMessage name='acceptTerms' component='div' className='invalid-feedback' />
            </FormGroup>
            <FormGroup>

            </FormGroup>
            <FormGroup className='text-align-center'>
              <div className='g-recaptcha' data-sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}></div>
              <Button type='submit' className='login-button' color='secondary'>Register</Button>
            </FormGroup>
          </FormikForm >
        )}
      </Formik>
    );
  }

  const socialLogins = () => {
    return (
      <Container >
        <Row className='social-login1'>
          <div className='login-button'>
            <MicrosoftLoginButton onClick={() => {
              window.location.href = API.getAPIEndpoint('/auth/microsoft');
            }} />
          </div>
        </Row>

        <Row className='social-login1'>
          <div className='login-button'>
            <GoogleLoginButton onClick={() => {
              window.location.href = API.getAPIEndpoint('/auth/google');
            }} />
          </div>
        </Row>

        <Row className='social-login1'>
          <div className='login-button'>
            <LinkedInLoginButton onClick={() => {
              window.location.href = API.getAPIEndpoint('/auth/linkedin');
            }} />
          </div>
        </Row>

        <Row className='social-login1'>
          <div className='login-button'>
            <TwitterLoginButton onClick={() => {
              window.location.href = API.getAPIEndpoint('/auth/twitter');
            }} />
          </div>
        </Row>

        <Row className='social-login1'>
          <div className='login-button'>
            <FacebookLoginButton onClick={() => {
              window.location.href = API.getAPIEndpoint('/auth/facebook');
            }} />
          </div>
        </Row>

        <Row className='social-login1'>
          <div className='login-button'>
            <GithubLoginButton onClick={() => {
              window.location.href = API.getAPIEndpoint('/auth/github');
            }} />
          </div>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Container className='login'>
        <Row className='alert-placement'>
          {
            loginErrorAlert()
          }
        </Row>
        <Row className='login-container rounded-border'>
          <Col md={5} className='social-logins'>
            {
              socialLogins()
            }
          </Col>
          <Col md={7}>
            {
              loginForm()
            }
          </Col>
        </Row>
      </Container>

      <UserDialog className='register-dialog'
        visible={registerOverlay}
        title={
          <b>Register</b>
        }
        onClose={handleRegistrationDialogClose}
      >
        {
          registrationForm()
        }
      </UserDialog>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    userStatus: state.reducerUserStatus
  }
}

export default connect(mapStateToProps)(LoginPage);